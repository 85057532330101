#siteDownIcon {
    margin: 0 auto;
    padding-left: 0;
    width: 150px;
    height: auto;
}

#siteDownIcon>img {
    width: 150px;
    position: relative;
    top: -8px;
}

.red-text {
    color: #ac1b1b;
    font-weight: bold;
}

.fa {
    margin-top: 3px;
    padding-right: 5px;
    width: 38px;
    height: 38px;
    font: normal normal normal 27px/1 FontAwesome !important;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fas {
    color: #007a74;
    margin-top: 3px;
    padding-right: 5px;
    width: 38px;
    height: 38px;
    font-size: 30px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p.phone {
    font-weight: bold;
    margin-top: 12px;
    margin-bottom: 8px !important;
    width: 100%;
}

#resources {
    margin-top: 20px;
    border-top: solid 1px;
    padding-top: 20px;
}

main #resources>h2,
main #resources>h3,
main #resources>p {
    padding-left: 20px;
}

main #resources>h3 {
    padding-bottom: 15px;
}

/* Card */
.card {
    background-color: white;
    padding: 20px;
    margin: 20px 0 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .32);
    border-radius: 5px;
}

.card h2 {
    margin-top: 0;
}

/* Box Links */
.link-teal {
    border-bottom: 7px solid #00b2a9;
}

.link-lightbrown {
    border-bottom: 7px solid #83786f;
}

.link-mint {
    border-bottom: 7px solid #6cc24a;
}

.link-mocha {
    border-bottom: 7px solid #5c462b;
}

.link-warmgray {
    border-bottom: 7px solid #aaaaaa;
}

.link-slate {
    border-bottom: 7px solid #5f768c;
}

.link-creamycoffee {
    border-bottom: 7px solid #b9975b;
}

.link-mediumgray {
    border-bottom: 7px solid #888b8d;
}

.link-Countyblue {
    border-bottom: 7px solid #01426a;
}

#boxLinks {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-bottom: 15px;
}

@media (max-width: 710px) {
    #boxLinks {
        justify-content: space-evenly;
    }
}

#boxLinks>li {
    margin: 0 6px;
    list-style: none;
    padding: 12px 15px 5px 15px;
    background-color: white;
    text-align: center;
    width: 170px;
    min-height: 160px;
    margin-bottom: 15px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: space-between;
    box-shadow: 0 0 10px rgba(0, 0, 0, .32);
    border-radius: 5px;
}

#boxLinks>li p {
    margin-bottom: 0;
}

@media (min-width: 710px) {
    #boxLinks {
        padding: 0 15px;
    }
}

@media (max-width: 435px) {
    #boxLinks>li {
        width: 155px;
    }
}

#boxLinks>li span {
    width: 100%;
}

#boxLinks>li>svg {
    margin-top: 15px;
    font-size: 33px !important;
    height: 32px;
    width: 32px;
}

#boxLinks>li>a>svg {
    margin-top: 15px;
    font-size: 33px !important;
    height: 32px;
    width: 32px;
}

#boxLinks .social-list {
    margin-top: 12px;
}

#boxLinks .social-list .social-icons {
    margin: 0 5px;
}

#boxLinks li.social-icons {
    background-color: #fff;
    border: 2px solid #007a74;
}

#boxLinks li.social-icons a {
    color: #007a74 !important;
}

#boxLinks li.social-icons:hover {
    background-color: #007a74;
    border: 2px solid #007a74;
}

#boxLinks li.social-icons:hover a {
    color: #fff !important;
}

/* Social Icons */
.social-list {
    margin-top: 25px;
}

.social-list ul {
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

li.social-icons {
    display: inline-block;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    -webkit-font-smoothing: subpixel-antialiased;
    text-rendering: geometricPrecision;
    box-sizing: border-box;
    background-color: #f2f5f7;
    border: 2px solid #01426a;
}

li.social-icons a {
    text-align: center;
    font-size: 32px !important;
}

li.social-icons:hover {
    background-color: #007a74;
    border: 2px solid #007a74;
    color: white;
}

li.social-icons a>svg {
    height: 28px;
    width: auto;
    top: 3px;
    position: relative;
}

/* X icon */
#boxLinks .social-icons a>svg.Xicon {
    fill: #007a74;
}

#footer .social-icons a>svg.Xicon {
    fill: #01426A;
}

#boxLinks .social-icons:hover a>svg.Xicon,
#footer .social-icons:hover a>svg.Xicon {
    fill: #FFFFFF;
}

@media (max-width: 992px) {
    h1 {
        text-align: center;
    }

    #boxLinks {
        justify-content: center;
    }
}